import { BaseSyntheticEvent, FormEvent, useContext, useState } from 'react'
import { useForm } from 'react-hook-form'
import { m, AnimatePresence } from 'framer-motion'
import cx from 'classnames'
import BlockContent from '@sanity/block-content-to-react'

import { SanityDemoFormBlock } from '@data/sanity/queries/types/blocks'
import {
  triggerBookADemoEvent,
  triggerNewsletterSignUpEvent,
} from '@lib/analytics'
import { fadeAnimation } from '@lib/animate'
import { LanguageContext } from '@lib/language'
import { serializers } from '@lib/serializers'
import { addEmailToNewsletterList, bookADemo } from '@lib/services'
import { StringsContext } from '@lib/strings'

import Alert from '@components/alert'
import Button, {
  ButtonColor,
  ButtonSize,
  ButtonVariant,
  getButtonColor,
  getButtonIconAlignment,
  getButtonSize,
  getButtonVariant,
} from '@components/buttons/button'
import Checkbox from '@components/checkbox'
import InputField from '@components/input-field'
import TelInputField from '@components/tel-input-field'

interface DemoFormProps extends Omit<SanityDemoFormBlock, '_key' | '_type'> {
  id: string
  className?: string
  isInvertedColors?: boolean
}

interface DemoFormValues {
  email: string
  firstName: string
  lastName: string
  phoneNumber?: string
  company?: string
  acceptTerms?: boolean
  subscribeToNewsletter?: boolean
}

const phoneNumberField = 'phoneNumber'

const DemoForm = ({
  id,
  service,
  hubSpotFormId,
  hubSpotNewsletterFormId,
  klaviyoListID,
  klaviyoNewsletterListID,
  newsletterStatement,
  terms,
  hidePhoneInput,
  submit,
  successMsg,
  errorMsg,
  className,
  isInvertedColors,
  buttonStyle,
  strings: formStrings,
}: DemoFormProps) => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [isError, setIsError] = useState(false)
  const {
    handleSubmit,
    register,
    watch,
    reset,
    formState: { errors },
    setValue,
    trigger,
  } = useForm<DemoFormValues>()
  const strings = useContext(StringsContext)
  const { locale } = useContext(LanguageContext)

  const hasAcceptedTerms = watch('acceptTerms')
  const subscribeToNewsletter = watch('subscribeToNewsletter')

  // Call to reset the form
  const resetForm = (e: FormEvent) => {
    e.preventDefault()
    reset()
    setIsError(false)
    setIsSuccess(false)
    setIsSubmitting(false)
  }

  // handle form submission
  const onSubmit = async (
    demoFormValues: DemoFormValues,
    event?: BaseSyntheticEvent
  ) => {
    event?.preventDefault()

    const bookingTargetId =
      service === 'klaviyo'
        ? klaviyoListID
        : service === 'hubSpot'
        ? hubSpotFormId
        : null
    const newsletterTargetId =
      service === 'klaviyo'
        ? klaviyoNewsletterListID
        : service === 'hubSpot'
        ? hubSpotNewsletterFormId
        : null

    setIsError(false)
    setIsSuccess(false)

    // Show an error if there is no booking target
    if (!bookingTargetId) {
      setIsError(true)
      return
    }

    // Stop if accepting of terms is required
    if (!hasAcceptedTerms && !!terms) {
      return
    }

    setIsSubmitting(true)

    try {
      if (subscribeToNewsletter && newsletterTargetId) {
        await addEmailToNewsletterList(
          locale,
          service,
          newsletterTargetId,
          !!hasAcceptedTerms,
          demoFormValues.email,
          `${demoFormValues.firstName} ${demoFormValues.lastName}`
        )
        triggerNewsletterSignUpEvent()
      }

      await bookADemo(
        locale,
        service,
        bookingTargetId,
        !!hasAcceptedTerms,
        demoFormValues.email,
        demoFormValues.firstName,
        demoFormValues.lastName,
        demoFormValues.phoneNumber,
        demoFormValues.company
      )
      triggerBookADemoEvent()

      setIsSuccess(true)
    } catch (error) {
      console.log(error)
      setIsError(true)
    }

    setIsSubmitting(false)
  }

  if (!hidePhoneInput) {
    register(phoneNumberField, {
      required: formStrings?.phoneNumberMissing ?? strings.phoneNumberMissing,
    })
  }

  const handlePhoneNumberChange = (value: string) => {
    setValue(phoneNumberField, value)
    trigger(phoneNumberField)
  }

  const firstNameRegister = register('firstName', {
    required: formStrings?.firstNameMissing ?? strings.firstNameMissing,
  })
  const lastNameRegister = register('lastName', {
    required: formStrings?.lastNameMissing ?? strings.lastNameMissing,
  })

  const emailRegister = register('email', {
    required: formStrings?.emailMissing ?? strings.emailMissing,
    pattern: {
      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
      message: formStrings?.emailInvalid ?? strings.emailInvalid,
    },
  })
  // const companyRegister = register('company')
  const acceptTermsRegister = register('acceptTerms')
  const subscribeToNewsletterRegister = register('subscribeToNewsletter')

  if (!service) {
    return null
  }

  return (
    <form className={cx(className)} onSubmit={handleSubmit(onSubmit)}>
      <AnimatePresence exitBeforeEnter>
        {!isError && !isSuccess && (
          <m.div
            initial="hide"
            animate="show"
            exit="hide"
            variants={fadeAnimation}
          >
            <div className="flex flex-col sm:flex-row sm:gap-4">
              <InputField
                id={`book-a-demo-${id}-firstName`}
                type="text"
                formRegister={firstNameRegister}
                placeholder={
                  formStrings?.firstNamePlaceholder ??
                  strings.firstNamePlaceholder
                }
                label={formStrings?.firstName ?? strings.firstName}
                className="sm:w-1/2 mb-4"
                errorMessage={errors.firstName?.message}
                isInvertedColors={isInvertedColors}
              />

              <InputField
                id={`book-a-demo-${id}-lastName`}
                type="text"
                formRegister={lastNameRegister}
                placeholder={
                  formStrings?.lastNamePlaceholder ??
                  strings.lastNamePlaceholder
                }
                label={formStrings?.lastName ?? strings.lastName}
                className="sm:w-1/2 mb-4"
                errorMessage={errors.lastName?.message}
                isInvertedColors={isInvertedColors}
              />
            </div>

            <InputField
              id={`book-a-demo-${id}-email`}
              type="email"
              formRegister={emailRegister}
              placeholder={
                formStrings?.emailAddressPlaceholder ??
                strings.emailAddressPlaceholder
              }
              label={formStrings?.emailAddress ?? strings.emailAddress}
              className="mb-4"
              errorMessage={errors.email?.message}
              isInvertedColors={isInvertedColors}
            />

            {!hidePhoneInput && (
              <TelInputField
                id={`book-a-demo-${id}-phoneNumber`}
                placeholder={
                  formStrings?.phoneNumberPlaceholder ??
                  strings.phoneNumberPlaceholder
                }
                label={formStrings?.phoneNumber ?? strings.phoneNumber}
                className="mb-4"
                errorMessage={errors.phoneNumber?.message}
                isInvertedColors={isInvertedColors}
                onChange={handlePhoneNumberChange}
              />
            )}

            {/* <InputField
              id={`book-a-demo-${id}-company`}
              type="text"
              formRegister={companyRegister}
              placeholder={
                formStrings?.companyPlaceholder ?? strings.companyPlaceholder
              }
              label={formStrings?.company ?? strings.company}
              className="mb-4"
              errorMessage={errors.company?.message}
              isInvertedColors={isInvertedColors}
            /> */}

            {(!!klaviyoNewsletterListID || !!hubSpotNewsletterFormId) &&
              !!newsletterStatement && (
                <Checkbox
                  id={`book-a-demo-${id}-subscribeToNewsletter`}
                  formRegister={subscribeToNewsletterRegister}
                  className="mb-3"
                  isInvertedColors={isInvertedColors}
                >
                  <BlockContent
                    renderContainerOnSingleChild
                    className={cx('rc rc-checkbox')}
                    blocks={newsletterStatement}
                    serializers={serializers}
                  />
                </Checkbox>
              )}

            {!!terms && (
              <Checkbox
                id={`book-a-demo-${id}-acceptTerms`}
                formRegister={acceptTermsRegister}
                className="mb-3"
                isInvertedColors={isInvertedColors}
              >
                <BlockContent
                  renderContainerOnSingleChild
                  className={cx('rc rc-checkbox')}
                  blocks={terms}
                  serializers={serializers}
                />
              </Checkbox>
            )}

            <div className="flex justify-center">
              <Button
                type="submit"
                className={cx('mt-3', buttonStyle?.fontCase ?? '', {
                  'w-full': buttonStyle?.isFullWidth,
                })}
                variant={
                  buttonStyle?.variant
                    ? getButtonVariant(buttonStyle.variant)
                    : ButtonVariant.FILLED
                }
                size={
                  buttonStyle?.size
                    ? getButtonSize(buttonStyle.size)
                    : ButtonSize.NORMAL
                }
                color={
                  buttonStyle?.color
                    ? getButtonColor(buttonStyle.color)
                    : ButtonColor.GREEN_LIGHT
                }
                icon={buttonStyle?.icon}
                iconAlignment={getButtonIconAlignment(
                  buttonStyle?.iconAlignment
                )}
                disabled={isSubmitting || (!!terms && !hasAcceptedTerms)}
              >
                {submit}
              </Button>
            </div>
          </m.div>
        )}

        {isSuccess && (
          <m.div
            key="success"
            initial="hide"
            animate="show"
            exit="hide"
            variants={fadeAnimation}
          >
            <Alert>
              <BlockContent
                renderContainerOnSingleChild
                className="rc rc-alert"
                blocks={successMsg}
                serializers={serializers}
              />
            </Alert>
          </m.div>
        )}

        {isError && (
          <m.div
            key="error"
            initial="hide"
            animate="show"
            exit="hide"
            variants={fadeAnimation}
          >
            <Alert
              buttonText={formStrings?.buttonTryAgain ?? strings.buttonTryAgain}
              onClick={resetForm}
            >
              <BlockContent
                renderContainerOnSingleChild
                className="rc rc-alert"
                blocks={errorMsg}
                serializers={serializers}
              />
            </Alert>
          </m.div>
        )}
      </AnimatePresence>
    </form>
  )
}

export default DemoForm
